import clsx from "clsx";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/UI/buttons/Button";
import MotionDiv from "../../components/layout/MotionDiv";
import { useAppDispatch } from "../../state/hooks";
import { setAppData } from "../../state/slices/app";

import logo from "../../assets/img/logo-kba.svg";
import image from "../../assets/img/tasche.png";

const Start = () => {
  let navigate = useNavigate();
  let dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setAppData({ invocationIsValid: true }));
  });

  return (
    <MotionDiv className="h-full">
      <div className={clsx("flex flex-col items-center  h-full pt-8 pb-8 text-white bg-center bg-cover px-8 justify-around")}>
        <div>
          <img
            src={logo}
            alt="Logo"
            className="h-18 drop-shadow-xl"
          />

          <p className="mt-2 text-2xl text-center font-kbheadline text-pretty">
            Digital collectibles
            <br />
            with a touch
          </p>

          <img
            src={image}
            alt=""
            className="h-[35vh] bg-kb-light-gray mt-6 mx-auto"
          />

          <div className="w-full px-6 mt-12">
            <Button
              onClick={() => {
                navigate("/tutorial");
              }}
              title="Verify your GEMME card"
              className="w-full"
            />
          </div>
        </div>
      </div>
    </MotionDiv>
  );
};

export default Start;
