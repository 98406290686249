import { useNavigate } from "react-router-dom";
import MotionDiv from "../../components/layout/MotionDiv";
import CloseButton from "../../components/UI/buttons/CloseButton";
import HelpButton from "../../components/UI/buttons/HelpButton";
import NetworkIndicator from "./NetworkIndicator";
import ProgressBar from "./ProgressBar";
import SwipeField from "./SwipeField";
import SwipeMessage from "./SwipeMessage";

const Swipe = () => {
  let navigate = useNavigate();

  return (
    <MotionDiv className="h-full">
      <div className="h-full">
        <SwipeField />
        <ProgressBar />
        <NetworkIndicator />
        <SwipeMessage />
        <div className="absolute top-0 left-0 z-40">
          <CloseButton onClick={() => navigate("/tutorial", { replace: true })} />
        </div>
        <div className="absolute top-0 right-0 z-40">
          <HelpButton onClick={() => window.location.replace("https://karinweigelt.notion.site/Using-the-Soulmade-card-1a5646425a3f4e848662a4e3fa0985d2")} />
        </div>
      </div>
    </MotionDiv>
  );
};

export default Swipe;
