import { AnimatePresence } from "framer-motion";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import WithOrientationNotice from "./components/layout/WithOrientationNotice";

import Gem from "./pages/Gem";
import Start from "./pages/Start";
import Success from "./pages/Success";
import Swipe from "./pages/Swipe";
import Tutorial from "./pages/Tutorial";
import Minting from "./pages/Minting";

import blurred from "./assets/img/blurred.jpg";
import WithInvocationCheck from "./components/layout/WithInvocationCheck";

export const validStartPaths = ["/"];

const RouterComponent = () => {
  const location = useLocation();

  return (
    <div
      className="w-full h-full bg-top bg-no-repeat bg-kb-background"
      style={{ backgroundImage: `url(${blurred})` }}
    >
      <AnimatePresence exitBeforeEnter>
        <WithInvocationCheck path={location.pathname}>
          <WithOrientationNotice>
            <Routes
              location={location}
              key={location.pathname}
            >
              <Route
                path="/"
                element={<Start />}
              />

              <Route
                path="/tutorial"
                element={<Tutorial />}
              />
              <Route
                path="/swipe"
                element={<Swipe />}
              />
              <Route
                path="/success"
                element={<Success />}
              />
              <Route
                path="/Minting"
                element={<Minting />}
              />
              <Route
                path="/gem"
                element={<Gem />}
              />
              <Route
                path="*"
                element={
                  <Navigate
                    to="/"
                    replace
                  />
                }
              />
            </Routes>
          </WithOrientationNotice>
        </WithInvocationCheck>
      </AnimatePresence>
    </div>
  );
};

export default RouterComponent;
