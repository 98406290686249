import Card from "../../components/UI/Card";
import Button from "../../components/UI/buttons/Button";
import MotionDiv from "../../components/layout/MotionDiv";
import Details from "./Details";

import img from "../../assets/img/kette.gif";

const Gem = () => {
  return (
    <MotionDiv className="h-full overflow-scroll">
      <div className="flex flex-col items-center justify-between min-h-full px-8 pt-8 pb-8 text-center text-white">
        <Card className="bg-kb-background-darker">
          <div className="text-xl font-semibold text-center">Your personal GEMME NFT has been minted to your wallet.</div>
        </Card>

        <img
          src={img}
          className="h-auto w-[70dvw] border-4 border-white"
          alt="gem"
        />

        <div className="w-full px-6 space-y-4 text-center">
          <Button
            onClick={() => {
              const anchorEl = document.getElementById("details");
              if (anchorEl) {
                anchorEl.scrollIntoView({ behavior: "smooth" });
              }
            }}
            title="Show Origin"
            className="w-full mx-auto"
          />
        </div>
      </div>

      <Details />
    </MotionDiv>
  );
};

export default Gem;
