import { Tab } from "@headlessui/react";
import { Fragment, ReactNode } from "react";
import Button from "../../../components/UI/buttons/Button";
import About from "./About";
import NFTDetails from "./NFTDetails";
import PrintDetails from "./PrintDetails";

import img from "../../../assets/img/kette.jpg";

export type ItemType = string | string[];

type DetailDataType = {
  aboutDetailsEntries: ReactNode;
  nftDetailsEntries: ItemType[];
  printDetailsEntries: ItemType[];
  marketplace?: string;
  explorer?: string;
  mediaDownload?: string;
};

const Details = () => {
  const detailData: DetailDataType = {
    aboutDetailsEntries: (
      <div>
        <h2 className="uppercase">Gemme</h2>
        <p className="mt-4">
          “LESS IS MORE” remains one of the most appropriate definitions for luxury, an elusiveness that becomes status symbol and approaches a select and elite
          audience.
        </p>
        <img
          src={img}
          alt="GEMME"
          className="my-4 border-4 border-white"
        />
        <p className="mt-4">The word "jewel" comes from joy and indicates something precious, something of great value.</p>
        <p className="mt-4">
          A jewel is transformed into a code of communication in society and expression of its status for those who wear it. It is a transition from a simple
          goldsmith’s art object to a means of communication. A jewel is a work of art and at the same time of decoration, with undeniable intrinsic qualities:
          it enhances the one or the one who receives it.
        </p>
      </div>
    ),
    nftDetailsEntries: [
      ["Name", "Koenig & Bauer GEMME"],
      ["Artist", "Koenig & Bauer"],
      ["Token ID", "1"],
      ["Contract address", "0xE730745086fbC5cE7393510E8d1F20e67a795574"],
      ["Current Owner", "0xeb0B0896024C34Bc70ECDF4f28F49f3ef2763d78"],
      ["Last transaction", "24.05.2024"],
      ["Blockchain", "Ethereum"],
    ],
    printDetailsEntries: [
      ["Name", "GEMME No 1"],
      ["Designer", "Koenig & Bauer"],
      ["Print authorized", "Exclusive Demo"],
      ["Print date", "Drupa 2024"],
      ["Print ID", "001"],
      ["Limited edition", "001/250"],
      ["Size", "60 x 90"],
      ["Print Quality", "Silk Premium Paper"],
    ],
    marketplace: "https://opensea.io/assets/ethereum/0xe730745086fbc5ce7393510e8d1f20e67a795574/1/",
    explorer: "https://etherscan.io/nft/0xe730745086fbc5ce7393510e8d1f20e67a795574/1",
  };

  return (
    <div
      className="flex flex-col items-center justify-between h-full px-6 pt-8 pb-4 text-white"
      id="details"
    >
      <div className="flex flex-col w-full h-full">
        <Tab.Group>
          <Tab.List className="grid w-full grid-cols-3 text-sm">
            <Tab as={Fragment}>
              {({ selected }) => (
                <Button
                  title="About"
                  primary={selected}
                  className="py-1.5"
                />
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <Button
                  title="Print Details"
                  primary={selected}
                  className="py-1.5"
                />
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <Button
                  title="NFT Details"
                  primary={selected}
                  className="py-1.5"
                />
              )}
            </Tab>
          </Tab.List>

          <Tab.Panels className="mt-4 overflow-hidden grow">
            <Tab.Panel className="flex flex-col h-full overflow-scroll text-sm">
              <About children={detailData.aboutDetailsEntries} />
            </Tab.Panel>

            <Tab.Panel className="flex flex-col h-full overflow-scroll text-sm">
              <PrintDetails data={detailData.printDetailsEntries} />
            </Tab.Panel>

            <Tab.Panel className="flex flex-col h-full overflow-scroll text-sm">
              <NFTDetails
                data={detailData.nftDetailsEntries}
                mediaDownload={detailData.mediaDownload}
                marketplace={detailData.marketplace}
                explorer={detailData.explorer}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default Details;
