import { ItemType } from "..";
import Button from "../../../../components/UI/buttons/Button";

import img from "../../../../assets/img/kette.gif";

type Props = {
  data: ItemType[];
  mediaDownload?: string;
  marketplace?: string;
  explorer?: string;
};

const NFTDetails = (props: Props) => {
  if (!props.data) return null;

  return (
    <>
      <dl
        className="p-6 divide-y bg-kb-background-darker rounded-xl divide-kb-light-gray/25"
        id="NFTDetails"
      >
        {props.data.map((entry, index) => {
          if (entry.length === 1) {
            return (
              <div
                className="grid items-center grid-cols-2 gap-2 px-4 py-2"
                key={index}
              >
                <dt className="font-bold line-clamp-2">{entry[0]}</dt>
              </div>
            );
          } else {
            return (
              <div
                className="grid items-center grid-cols-2 gap-2 px-4 py-2"
                key={index}
              >
                <dt className="line-clamp-2 text-kb-light-gray">{entry[0]}</dt>
                <dd
                  className="font-bold break-all line-clamp-2"
                  dangerouslySetInnerHTML={{ __html: entry[1] }}
                ></dd>
              </div>
            );
          }
        })}
      </dl>

      <img
        src={img}
        alt="Gem"
        className="h-auto mx-auto mt-4 w-[70dvw]  border-4 border-white"
      />

      <div className="px-8 pt-4 mt-auto">
        {props.mediaDownload && (
          <Button
            href={props.mediaDownload}
            title="Media Download"
            className="w-full mt-10"
            target="_blank"
            rel="noreferrer"
          />
        )}

        {props.marketplace && (
          <Button
            title="Go to NFT Marketplace"
            href={props.marketplace}
            className="w-full mt-4"
            target="_blank"
            rel="noreferrer"
          />
        )}

        {props.explorer && (
          <Button
            title="View in blockchain explorer"
            href={props.explorer}
            className="w-full mt-4"
            target="_blank"
            rel="noreferrer"
          />
        )}
      </div>
    </>
  );
};

export default NFTDetails;
