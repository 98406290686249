import { ItemType } from "..";
import img from "../../../../assets/img/nftcard.png";
type Props = {
  data: ItemType[];
};

const PrintDetails = (props: Props) => {
  return (
    <>
      <dl className="p-6 divide-y bg-kb-background-darker rounded-xl divide-kb-light-gray/25">
        {props.data.map((entry, index) => {
          if (entry.length === 1) {
            return (
              <div
                className="grid items-center px-4 py-2 font-bold"
                key={index}
                id="PrintDetails"
              >
                {entry[0]}
              </div>
            );
          } else {
            return (
              <dl
                className="grid items-center grid-cols-2 gap-2 px-4 py-2 "
                key={index}
                id="PrintDetails"
              >
                <dt className="line-clamp-2 text-kb-light-gray">{entry[0]}</dt>
                <dd
                  className="font-bold line-clamp-2"
                  dangerouslySetInnerHTML={{ __html: entry[1] }}
                ></dd>
              </dl>
            );
          }
        })}
      </dl>

      <img
        src={img}
        className="h-auto mx-auto mt-4"
        alt="card"
      />
    </>
  );
};

export default PrintDetails;
