import { ConnectivityResponse, UsabilityResponse } from "@soulmade/prismaid";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sound from "../../../components/Sound";
import LoadedImage from "../../../components/UI/images/LoadedImage";
import Spinner from "../../../components/UI/Spinner";
import { getScaleFactor, getScaleFactorFromLocalStorage } from "../../../helper/scale";
import useTimeout from "../../../hooks/useTimeout";
import SDKSingleton from "../../../SDK";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { addSwipeMessage, requestInitialTouchMessage, setConnectivityStatus, setIsActive, setProgress, setScaleFactor } from "../../../state/slices/swipe";
import { RootState } from "../../../state/store";
import BrowserNotSupportedModal from "./modals/BrowserNotSupportedModal";
import DeviceNotSupportedModal from "./modals/DeviceNotSupportedModal";
import GloveModeModal from "./modals/sensitivity/GloveModeModal";
import PointerSpeedModal from "./modals/sensitivity/PointerSpeedModal";
import TouchSensitivityModal from "./modals/sensitivity/TouchSensitivityModal";
import ShouldAddToHomeScreenModal from "./modals/ShouldAddToHomeScreenModal";

import placement_cutout from "../../../assets/img/03_scan/scan_card-cutout.png";
import target from "../../../assets/img/03_scan/scan_endpoint.png";
import swiper from "../../../assets/img/03_scan/scan_swipe-escalator.png";
import landing_cutout from "../../../assets/img/03_scan/scan_swipe-landing-cutout.png";

import error from "../../../assets/sounds/error.mp3";
import success from "../../../assets/sounds/success.mp3";

const messages = [
  { key: "card_unstable", value: "Please swipe again and hold the card firm." },
  { key: "invalid_signal", value: "Please swipe again." },
  { key: "swipe_faster", value: "Please swipe over the card more quickly." },
  { key: "swipe_slower", value: "Please swipe over the card more slowly." },
  { key: "swipe_without_card", value: "Please swipe over the card." },
];

// component
const FunctionalSwipeField = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const scaleFactor = useAppSelector((state: RootState) => state.swipe.scaleFactor);
  const isActive = useAppSelector((state: RootState) => state.swipe.isActive);

  const [showModal_TouchSensitivity, setShowModal_TouchSensitivity] = useState(false);
  const [showModal_GloveMode, setShowModal_GloveMode] = useState(false);
  const [showModal_pointerspeed, setShowModal_pointerspeed] = useState(false);
  const [showModal_ShouldAddToHomeScreen, setShowModal_ShouldAddToHomeScreen] = useState(false);
  const [showModal_browserNotSupported, setShowModal_browserNotSupported] = useState(false);
  const [showModal_deviceNotSupported, setShowModal_deviceNotSupported] = useState(false);

  const successSound = useRef(new Sound(success));
  const errorSound = useRef(new Sound(error));

  const [flashRedAlert, setFlashRedAlert] = useState(false);
  const [flashGreenAlert, setFlashGreenAlert] = useState(false);

  const [sdk] = useState(SDKSingleton.getInstance().sdk);

  // configure sdk
  useEffect(() => {
    sdk.resume();

    let initialisationSubject = sdk.getInitialisationSubject().subscribe((response) => {
      console.log("*) initialisationResponse", response);

      if (response.ppi) {
        var scale = getScaleFactor(response.ppi, response.devicePixelRatio);

        if (!Number.isNaN(scale)) {
          dispatch(setScaleFactor(scale));
        }
      } else {
        setShowModal_deviceNotSupported(true);
        return;
      }

      if (response.deviceSupport?.requirements?.includes("touchsensitivity")) {
        setShowModal_TouchSensitivity(true);
        return;
      }

      if (response.deviceSupport?.requirements?.includes("glovemode")) {
        setShowModal_GloveMode(true);
        return;
      }

      if (response.deviceSupport?.requirements?.includes("pointerspeed")) {
        setShowModal_pointerspeed(true);
        return;
      }
    });

    const usabilitySubject = sdk.getUsabilitySubject().subscribe((response: UsabilityResponse) => {
      console.log("*) usabilityResponse", response);
      if (response.event === "device_not_supported") {
        setShowModal_deviceNotSupported(true);
        return;
      }
      if (response.event === "browser_not_supported") {
        setShowModal_browserNotSupported(true);
        return;
      }
      if (response.event === "display_too_small_displacement") {
        setShowModal_deviceNotSupported(true);
        return;
      }
      if (response.event === "display_small_should_add_to_home") {
        setShowModal_ShouldAddToHomeScreen(true);
        return;
      }
    });

    const detectionSuccessSubject = sdk.getDetectionSuccessSubject().subscribe((response) => {
      console.log("*) detection success:", response.description());

      if (response.codeId === "code40") {
        handleSwipeSuccess();
      }
    });

    const detectionErrorSubject = sdk.getDetectionErrorSubject().subscribe((response) => {
      console.log("*) detection error:", response.description());
      response.hints.forEach((hint) => {
        console.log("*) hint:", hint.description());
      });

      let hintCode = "";
      // filter for handled error codes
      let codes = response.hints.filter((hint) => {
        return (
          hint.code === "card_unstable" ||
          hint.code === "invalid_signal" ||
          hint.code === "swipe_faster" ||
          hint.code === "swipe_slower" ||
          hint.code === "swipe_without_card"
        );
      });

      if (codes.length > 0) {
        hintCode = codes[0].code;
      }

      switch (hintCode) {
        case "card_unstable":
        case "invalid_signal":
        case "swipe_faster":
        case "swipe_slower":
        case "swipe_without_card":
          dispatch(
            addSwipeMessage({
              title: "Please repeat",
              message: messages[messages.findIndex((item) => item.key === hintCode)]?.value,
            })
          );
          break;
        default:
          // unknown code or ""
          dispatch(
            addSwipeMessage({
              title: "Please repeat",
              message: "Please swipe again.",
            })
          );
          break;
      }

      clickErrorButton();
    });

    const interactionSubject = sdk.getInteractionSubject().subscribe((response) => {
      console.log("*) interaction event:", response.event, response.activeSignals);

      switch (response.event) {
        case "started":
          dispatch(setIsActive(true));
          dispatch(requestInitialTouchMessage());
          break;
        case "changed":
          break;
        case "complete":
          dispatch(setIsActive(false));
          dispatch(setProgress(0));
          break;
        default:
          break;
      }
    });

    const progressSubject = sdk.getProgressSubject().subscribe((response) => {
      console.log("*) progress:", response.progress);
      dispatch(setProgress(response.progress));
    });

    const connectivitySubject = sdk.getConnectivitySubject().subscribe((response: ConnectivityResponse) => {
      console.log("*) connectivity response:", response.status);

      if (response.status === null) return;

      dispatch(setConnectivityStatus(response.status));
    });

    const screen = document.querySelector("#swipeScreen");
    if (screen) {
      sdk.attachToElement(screen);
    }

    return () => {
      initialisationSubject.unsubscribe();
      usabilitySubject.unsubscribe();
      progressSubject.unsubscribe();
      connectivitySubject.unsubscribe();
      detectionSuccessSubject.unsubscribe();
      detectionErrorSubject.unsubscribe();
      interactionSubject.unsubscribe();

      dispatch(setIsActive(false));
      dispatch(setProgress(0));
    };
  }, []);

  useTimeout(() => {
    if (!scaleFactor) {
      let storageFactor = getScaleFactorFromLocalStorage();
      if (storageFactor) {
        dispatch(setScaleFactor(storageFactor));
      }
    }
  }, 2000);

  const handleSwipeSuccess = () => {
    sdk.pause();
    successSound.current.play();
    showGreenAlert();

    setTimeout(() => {
      navigate("/success", { replace: true });
    }, 1500);

    setTimeout(() => {
      dispatch(setProgress(0));
    }, 3000);
  };

  // selector is not updating when called from subscription
  // works fine when using with button onClick
  // temporary workaround: use hidden button
  // will be fixed eventually by a new react hook useEvent()
  // TODO useCallback?
  const clickErrorButton = () => {
    let btn = document.getElementById("errorButton") as HTMLButtonElement;
    btn.click();
  };
  const handleSwipeError = () => {
    errorSound.current.sound.currentTime = 0;
    errorSound.current.play();

    showRedAlert();
  };

  const showRedAlert = () => {
    setFlashRedAlert(true);
  };
  const showGreenAlert = () => {
    setFlashGreenAlert(true);
  };

  const closeTouchModalAndRestartAnimation = () => {
    setShowModal_TouchSensitivity(false);
    setShowModal_pointerspeed(false);
    setShowModal_GloveMode(false);
  };

  return (
    <>
      <div
        id="swipeScreen"
        className="absolute top-0 left-0 w-screen h-full overflow-hidden"
      >
        {!scaleFactor && !(scaleFactor > 0) ? (
          <>
            <div className="absolute inset-0 flex items-center justify-center text-white">
              <Spinner />
            </div>
          </>
        ) : (
          <>
            <LoadedImage
              src={placement_cutout}
              id="placement"
              alt="placement"
              horizontalAlign="left"
              verticalAlign="bottom"
              horizontalOffset={20}
              verticalOffset={-60}
              scaleFactor={scaleFactor}
              opacity={0.4}
            />

            {flashRedAlert && (
              <AnimatePresence>
                <motion.div
                  className="absolute inset-0 h-full bg-status-red/50 touch-none"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: [100, 0] }}
                  transition={{
                    duration: 0.2,
                    ease: "linear",
                    repeat: 1,
                    repeatDelay: 0.2,
                  }}
                  onAnimationComplete={() => {
                    setFlashRedAlert(false);
                  }}
                />
              </AnimatePresence>
            )}
            {flashGreenAlert && (
              <AnimatePresence>
                <motion.div
                  className="absolute inset-0 h-full bg-status-green/50 touch-none"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: [100, 0] }}
                  transition={{
                    duration: 0.2,
                    ease: "linear",
                    repeat: 1,
                    repeatDelay: 0.2,
                  }}
                  onAnimationComplete={() => {
                    setFlashGreenAlert(false);
                  }}
                />
              </AnimatePresence>
            )}

            <LoadedImage
              src={landing_cutout}
              id="landing"
              alt="landing"
              horizontalAlign="left"
              verticalAlign="bottom"
              horizontalOffset={448}
              verticalOffset={322}
              scaleFactor={scaleFactor}
            />

            <motion.div
              variants={{
                hidden: {
                  opacity: 0,
                  transition: { duration: 0.3 },
                },
                visible: {
                  opacity: 1,
                  transition: { duration: 0.3 },
                },
              }}
              initial="hidden"
              animate={isActive ? "visible" : "hidden"}
            >
              <LoadedImage
                src={target}
                id="target"
                alt="target"
                horizontalAlign="left"
                verticalAlign="bottom"
                horizontalOffset={476}
                verticalOffset={-1220}
                scaleFactor={scaleFactor}
              />
            </motion.div>

            <motion.div
              variants={{
                hidden: {
                  y: "0%",
                  transition: { duration: 0 },
                },
                swiping: {
                  y: ["0%", "-200%"],
                  transition: { duration: 3, ease: "linear", repeat: Infinity, repeatDelay: 1, delay: 2 },
                },
              }}
              animate={isActive ? "hidden" : "swiping"}
              className="relative w-screen h-screen"
            >
              <LoadedImage
                src={swiper}
                id="swiper"
                alt="swiper"
                horizontalAlign="left"
                verticalAlign="bottom"
                horizontalOffset={448}
                verticalOffset={1900}
                scaleFactor={scaleFactor}
              />
            </motion.div>
          </>
        )}
      </div>

      <TouchSensitivityModal
        isOpen={showModal_TouchSensitivity}
        setIsOpen={closeTouchModalAndRestartAnimation}
      />
      <GloveModeModal
        isOpen={showModal_GloveMode}
        setIsOpen={closeTouchModalAndRestartAnimation}
      />
      <PointerSpeedModal
        isOpen={showModal_pointerspeed}
        setIsOpen={closeTouchModalAndRestartAnimation}
      />

      <ShouldAddToHomeScreenModal
        isOpen={showModal_ShouldAddToHomeScreen}
        setIsOpen={setShowModal_ShouldAddToHomeScreen}
      />
      <BrowserNotSupportedModal
        isOpen={showModal_browserNotSupported}
        setIsOpen={setShowModal_browserNotSupported}
      />
      <DeviceNotSupportedModal
        isOpen={showModal_deviceNotSupported}
        setIsOpen={setShowModal_deviceNotSupported}
      />

      {/* FIXME remove in production*/}
      {/*<div className="absolute z-30 space-x-2 bottom-2 left-10">
        <button
          onClick={() => handleSwipeSuccess()}
          id="successButton"
          className="p-2 text-white rounded bg-white/20"
        >
          swipeSuccess
        </button>

        <button
          onClick={() => handleSwipeError()}
          id="testErrorButton"
          className="p-2 text-white rounded bg-white/20"
        >
          swipeError
        </button>
      </div>*/}

      <div className="absolute z-10 space-x-2 bottom-2">
        <button
          onClick={() => handleSwipeError()}
          id="errorButton"
          className="hidden"
        >
          handleSwipeError
        </button>
      </div>
    </>
  );
};

export default FunctionalSwipeField;
