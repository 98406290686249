import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const About = ({ children }: Props) => {
  return (
    <div
      className="p-6  bg-kb-background-darker rounded-xl"
      id="AboutDetails"
    >
      {children}
    </div>
  );
};

export default About;
