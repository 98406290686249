import { motion } from "framer-motion";
import { useState } from "react";
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router-dom";
import Card from "../../components/UI/Card";
import Button from "../../components/UI/buttons/Button";
import MotionDiv from "../../components/layout/MotionDiv";

import check_light from "../../assets/img/04_verify/lottie-soulmade.json";
import card from "../../assets/img/nftcard.png";

const Success = () => {
  let navigate = useNavigate();

  const [cardIsVisible, setCardIsVisible] = useState(false);

  const handleOnClick = () => {
    navigate("/minting", { replace: true });
  };

  return (
    <MotionDiv className="h-full overflow-scroll">
      <div className="flex flex-col items-center justify-between min-h-full px-8 pt-8 pb-8 text-center text-white">
        <Card className="bg-kb-background-darker">
          <MotionDiv
            initial="initialHidden"
            animate="fadeIn"
            className="text-xl font-semibold text-center"
          >
            Authentic GEMME card verified
          </MotionDiv>
        </Card>

        <div>
          <Lottie
            animationData={check_light}
            loop={false}
            play={true}
            speed={1}
            className="h-24 mx-auto"
            onComplete={() => {
              setCardIsVisible(true);
            }}
          />
          <div className="h-[50dvh]">
            {cardIsVisible && (
              <motion.img
                src={card}
                alt="card"
                className="mx-auto h-[50dvh]"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: [0.8, 1.2, 1] }}
                transition={{ duration: 0.8, ease: "easeInOut" }}
              />
            )}
          </div>
        </div>

        <div className="w-full px-6 space-y-4 text-center">
          <Button
            onClick={handleOnClick}
            title="Claim your NFT"
            className="w-full mx-auto"
          />
        </div>
      </div>
    </MotionDiv>
  );
};

export default Success;
