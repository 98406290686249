import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../components/UI/Card";
import Button from "../../components/UI/buttons/Button";
import MotionDiv from "../../components/layout/MotionDiv";

import animation from "../../assets/img/tasche.png";
import img from "../../assets/img/wallets.jpg";

const Minting = () => {
  let navigate = useNavigate();
  const [isMinting, setisMinting] = useState(false);

  const handleOnClick = () => {
    setisMinting(true);
    setTimeout(() => {
      navigate("/gem", { replace: true });
    }, 2500);
  };

  return (
    <MotionDiv className="h-full overflow-scroll">
      {!isMinting && (
        <MotionDiv
          className="flex flex-col items-center justify-between min-h-full px-8 pt-8 pb-8 text-center text-white"
          initial="initialHidden"
          animate="fadeIn"
          exit="fadeOut"
        >
          <Card className="bg-kb-background-darker">
            <MotionDiv className="text-xl font-semibold text-center">Connect your wallet to claim your unique GEMME NFT!</MotionDiv>
          </Card>
          <button
            onClick={handleOnClick}
            title="Start Minting"
          >
            <img
              src={img}
              alt="card"
            />
          </button>
          <div className="w-full px-6 space-y-4 text-center">
            <Button
              onClick={handleOnClick}
              title="Start Minting"
              className="w-full mx-auto"
            />
          </div>
        </MotionDiv>
      )}
      {isMinting && (
        <MotionDiv
          className="flex flex-col items-center justify-between min-h-full px-8 pt-8 pb-8 text-center text-white"
          initial="initialHidden"
          animate="fadeIn"
          exit="fadeOut"
        >
          <Card className="bg-kb-background-darker">
            <MotionDiv className="text-xl font-semibold text-center">
              Minting in progress.
              <br />
              Unboxing your NFT!
            </MotionDiv>
          </Card>
          <img
            src={animation}
            alt="card"
            className="animate-bounce h-[50dvh]"
          />
          <div></div>
        </MotionDiv>
      )}
    </MotionDiv>
  );
};

export default Minting;
