import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type AppState = {
  invocationIsValid: boolean;
};

const initialState: AppState = {
  invocationIsValid: false,
};

export const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAppData: (state, action: PayloadAction<AppState>) => {
      state.invocationIsValid = action.payload.invocationIsValid;
    },
  },
});

export const appReducer = app.reducer;
export const { setAppData } = app.actions;
