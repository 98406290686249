import Modal from "../../../../components/layout/Modal";

type Props = {
  isOpen: boolean;
  setIsOpen: any;
};

const BrowserNotSupportedModal = (props: Props) => {
  return (
    <Modal
      title="Incompatible browser"
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
    >
      <p className="mt-4 whitespace-pre-wrap">
        Unfortunately, your browser is not supported.
        <br />
        <br />
        Please open the link on another smartphone.
      </p>
    </Modal>
  );
};

export default BrowserNotSupportedModal;
