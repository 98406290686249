import { ReactNode } from "react";
import clsx from "clsx";

type Props = {
  children: ReactNode;
  className?: string;
  small?: boolean;
  noPadding?: boolean;
  bgColor?: string;
};

const Card = ({ children, className, small, noPadding, bgColor = "bg-white" }: Props) => {
  return (
    <div
      className={clsx(
        "overflow-hidden text-center  rounded-lg shadow-md whitespace-pre-wrap",
        { "px-4 py-5 sm:p-6": !small && !noPadding },
        { "px-3 py-3 sm:p-6": small && !noPadding },
        { bgColor },
        className
      )}
    >
      {children}
    </div>
  );
};

export default Card;
